import React from "react";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";

import { useSelector } from "react-redux";
import RegisterService from "../../../services/register.service";
import Select from "react-select";
import * as XLSX from "xlsx";

import { quizSearch } from "../../../schema";
import { useFormik } from "formik";

export default function ManageRegisteredUsers() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);

  const [locations, setLocations] = useState([]);
  const [limit, setLimit] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [searchIdz, setSearchIdz] = useState("");

  const adminList = async (limit, page) => {
    setSearchIdz("");
    setLoading(true);
    try {
      const responce = await AdminListService.listRegisteredUsers(limit, page);

      if (responce.status === 200) {
        const res = responce.data.data.data;

        const results = [];
        res.map((value) => {
          return results.push({
            store_location: value.store_code + " - " + value.store_name,
            customerEmail: value.email,
            customer_F: value.cust_fname,
            customer_L: value.cust_lname,
            phone: value.phone,
            address: value.address,
            city: value.city,
            state: value.state,
            p_address: value.address2,
            zip: value.zip,
            sales_name: value.sales_name,
            date: value.date,
          });
        });

        settableData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);

      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      adminList(limit, currentPage);
      async function getStoreLocation() {
        let errors = {};

        try {
          //FIXME remove this service if not used
          const response = await RegisterService.getStoreLocation();
          let value = response.data.data;
          const results = [];
          value.map((value) => {
            return results.push({
              value: value.store_location,
              label: value.store_location + " - " + value.store_name,
            });
          });
          await setLocations([...results]);

          if (response.data.status === 429 || response.data.status === "429") {
            alert("Max Limit Reached. Please wait.");
          }
          if (value.includes("redirect")) {
          } else {
            errors["questionnare"] = response.data.response;
          }
        } catch (err) {
          setLocations([]);
          if (err.status === 429 || err.status === "429") {
            alert("Max Limit Reached. Please wait.");
          }
        }
      }
      getStoreLocation();
    }
  }, [adminAccessToken]);

  const searchInTable = async (limit, page, values) => {
    setSearchIdz("");
    setLoading(true);
    try {
      const responce = await AdminListService.searchRegisteredData(
        limit,
        page,
        values
      );

      if (responce.status === 200) {
        const res = responce.data.data.data;

        const results = [];
        res.map((value) => {
          return results.push({
            store_location: value.store_code + " - " + value.store_name,
            customerEmail: value.email,
            customer_F: value.cust_fname,
            customer_L: value.cust_lname,
            phone: value.phone,
            address: value.address,
            city: value.city,
            state: value.state,
            p_address: value.address2,
            zip: value.zip,
            sales_name: value.sales_name,
            date: value.date,
          });
        });

        settableData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);
        setSearchIdz(responce.data.data.searchIds.join("+"));

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setSearchIdz("");

      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        searchInTable(limit, "1", values);
      },
    });

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                No.
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customer_F")}
                className={getClassNamesFor("customer_F")}
              >
                Customer First Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customer_L")}
                className={getClassNamesFor("customer_L")}
              >
                Customer Last Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("customerEmail")}
                className={getClassNamesFor("customerEmail")}
              >
                Customer Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("phone")}
                className={getClassNamesFor("phone")}
              >
                Customer Phone#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("store_location")}
                className={getClassNamesFor("store_location")}
              >
                Showroom Location
              </th>
              <th
                scope="col"
                onClick={() => requestSort("sales_name")}
                className={getClassNamesFor("sales_name")}
              >
                Sales Associate #
              </th>
              <th
                scope="col"
                onClick={() => requestSort("address")}
                className={getClassNamesFor("address")}
              >
                Address
              </th>
              <th
                scope="col"
                onClick={() => requestSort("p_address")}
                className={getClassNamesFor("p_address")}
              >
                Apartment/Suite #
              </th>
              <th
                scope="col"
                onClick={() => requestSort("city")}
                className={getClassNamesFor("city")}
              >
                City
              </th>
              <th
                scope="col"
                onClick={() => requestSort("state")}
                className={getClassNamesFor("state")}
              >
                State
              </th>
              <th
                scope="col"
                onClick={() => requestSort("zip")}
                className={getClassNamesFor("zip")}
              >
                Zip Code
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.date}</td>
                  <td>{el.customer_F}</td>
                  <td>{el.customer_L}</td>
                  <td>{el.customerEmail}</td>
                  <td>{el.phone}</td>
                  <td>{el.store_location}</td>
                  <td>{el.sales_name}</td>
                  <td>{el.address}</td>
                  <td>{el.p_address}</td>
                  <td>{el.city}</td>
                  <td>{el.state}</td>
                  <td>{el.zip}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="13" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              if (values.fieldtype !== "" && values.searchval !== "") {
                searchInTable(limit, e, values);
              } else {
                adminList(limit, e);
              }
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };
  const exportRecord = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.exportSearchRegister(searchIdz);
      let resultData = responce.data.data;
      const worksheet = XLSX.utils.json_to_sheet(resultData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${responce.data.fileName}`);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                Registered Users [{totalResults}]
                {searchIdz !== "" && totalResults > 0 && (
                  <button
                    type="button"
                    onClick={exportRecord}
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    Export Record
                  </button>
                )}
              </h5>
            </div>

            <div className="card-body">
              <div className="clearfix"></div>
              <form className="row" onSubmit={handleSubmit} noValidate>
                <div className="col-md-3">
                  <div className="input-field-box mb-lg-0">
                    <label>Show Record</label>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        if (
                          values.fieldtype !== "" &&
                          values.searchval !== ""
                        ) {
                          searchInTable(e.target.value, "1", values);
                        } else {
                          adminList(e.target.value, "1");
                        }
                      }}
                    >
                      <option value="20">20</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="1500">1500</option>
                      <option value={totalResults}>All</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-3 ms-auto">
                  <div className="input-field-box mb-lg-0">
                    <label>Select Column</label>
                    <select
                      className={`form-select ${
                        errors.fieldtype && touched.fieldtype ? "is-danger" : ""
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("searchval", "");
                      }}
                      name="fieldtype"
                      value={values.fieldtype || ""}
                      required
                    >
                      <option value="">Select Column</option>
                      <option value="date">Date</option>
                      <option value="fname">First Name </option>
                      <option value="lname">Last Name </option>
                      <option value="email">Email </option>
                      <option value="phone">Phone </option>
                      <option value="showroom">Showroom Location </option>

                      <option value="sales_name">Sales Associate #</option>
                      <option value="address">Address</option>
                      <option value="p_address">Apartment/Suite #</option>
                      <option value="city">City</option>
                      <option value="state">State</option>
                      <option value="zip">Zip</option>
                    </select>
                  </div>
                  {errors.fieldtype && touched.fieldtype ? (
                    <span className="text-danger">{errors.fieldtype}</span>
                  ) : null}
                </div>

                <div className="col-lg-4 ps-lg-0">
                  <div className="input-field-box mb-lg-0">
                    <label>Search</label>
                    <div className="input-group">
                      {values.fieldtype === "showroom" ? (
                        locations.length ? (
                          <Select
                            className="border-0 form-control form-label mb-0 p-0"
                            placeholder={"Showroom Location"}
                            options={locations}
                            isSearchable={true}
                            isClearable={true}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setFieldValue(
                                  "searchval",
                                  selectedOption?.value
                                );
                              } else {
                                setFieldValue("searchval", "");
                              }
                            }}
                          />
                        ) : null
                      ) : (
                        <input
                          type={values.fieldtype === "date" ? "date" : "text"}
                          className={`form-control ${
                            errors.searchval && touched.searchval
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          name="searchval"
                          value={values.searchval || ""}
                          required
                        />
                      )}

                      <button className="btn btn-primary" type="submit">
                        Search
                      </button>
                      <button
                        className="btn btn-primary"
                        type="reset"
                        onClick={() => window.location.reload(false)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  {errors.searchval && touched.searchval ? (
                    <span className="text-danger">{errors.searchval}</span>
                  ) : null}
                </div>
              </form>
              <Table data={tableData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
